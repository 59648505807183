<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="rol"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>


          <v-col
            md="6"
            cols="12"
          >
            <v-select
                :items="permission"
                v-model="permission_selected"
                multiple
                label="Select permission"
                item-text="name"
                item-value="name"
                chips
                dense
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="createRol"
            >
              Save
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="cancel"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      permission: [],
      rol: '',
      permission_selected: [],
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    async getPermission() {
      await axios
        .get('/permission/get')
        .then(response => {
          this.permission = response.data
        })
        .catch(() => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to get roles!')
          console.log('error :>> ', error.response)
        })
    },
    createRol() {
      axios
        .post('/role/create', {
          name: this.rol,
          permissions: this.permission_selected,
        })
        .then(response => {
          console.log('updated')
          console.log(response.data)
          this.$alertify.success('Role created')
          this.$router.push('/role')
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    },
    cancel() {
      this.$router.push('/role')
    },
  },
  async mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
      await this.getPermission()
    } else {
      this.loading = false
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },
}
</script>
